import { Box, Grid, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
	Download as DownloadIcon,
	SmsOutlined as SmsOutlinedIcon,
	Portrait as PortraitIcon,
	Group as GroupIcon,
	SentimentVerySatisfied as SentimentVerySatisfiedIcon,
	Lightbulb as LightbulbIcon,
	EmailOutlined as EmailOutlinedIcon
} from '@mui/icons-material';
import { Chart } from 'react-google-charts';
import {
	SentimentCard,
	SentimentLevelEnum,
} from 'components/routes/analytics/SentimentCard';
import TextButton from 'components/common/styled/TextButton';
import { StatisticCard } from 'components/routes/analytics/StatisticCard';
import { LinearProgress } from 'components/common/LinearProgress';
import OutlinedButton from 'components/common/styled/OutlinedButton';
import useNumberParams from 'hooks/useNumberParams';
import { useQuery } from 'urql';
import {
	analyticsExportQuery,
	feedbackAnalyticsByProjectQuery,
	followAnalyticsByProjectQuery,
	visitorAnalyticsByProjectQuery,
} from 'api/query/analytics.queries';
import {
	FeedbackAnalytics,
	FollowersAnalytics,
	MetricCountOverTime,
	VisitorAnalytics,
} from 'types/types';
import { Item } from 'components/routes/analytics/Item';
import dayjs from 'dayjs';
import _ from 'lodash';
import AppDialog from 'components/common/Dialog';
import { FollowersList } from 'components/routes/analytics/FollowersList';
import { ExportModal } from 'components/routes/analytics/ExportModal';
import Loading from '../components/common/Loading';
import RegularButton from '../components/common/styled/RegularButton';
import { KeyStatsCard } from '../components/routes/analytics/KeyStatsCard';
import HelpScoutBeaconArticle from '../components/common/HelpScoutBeaconArticle';

const Analytics: FC = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [exportModalOpen, setExportModalOpen] = useState(false);
	const defaultFonts: string[] | undefined =
		theme.typography?.fontFamily?.split(',');
	// default to Helvetica on purpose to indicate that the theme is broken
	const defaultFont = defaultFonts ? defaultFonts[0] : 'Helvetica';
	const options = {
		title: 'Feedback over time',
		titleTextStyle: {
			fontName: defaultFont,
		},
		curveType: 'function',
		legend: { position: 'bottom', textStyle: { fontName: defaultFont } },
		hAxis: {
			format: 'MMM dd',
			baselineColor: theme.customPalette.dividerColors.dark,
			gridlines: {
				color: theme.customPalette.dividerColors.dark,
			},
			textStyle: {
				fontName: defaultFont,
			},
		},
		vAxis: {
			gridlines: {
				color: 'transparent',
			},
			textStyle: {
				fontName: defaultFont,
			},
			baselineColor: theme.customPalette.borderColors.light,
			viewWindow: {
				min: 0,
			},
		},
		chartArea: {
			height: '100%',
			width: '100%',
			top: 48,
			left: 100,
			right: 24,
			bottom: 64,
		},
	};

	const { projectId } = useNumberParams();

	const [{ data: visitorsData }] = useQuery({
		query: visitorAnalyticsByProjectQuery,
		variables: { projectId },
	});

	const [{ data: feedbackData }] = useQuery({
		query: feedbackAnalyticsByProjectQuery,
		variables: { projectId },
	});

	const [{ data: followerData }] = useQuery({
		query: followAnalyticsByProjectQuery,
		variables: { projectId },
	});

	const [{ data: exportData }] = useQuery({
		query: analyticsExportQuery,
		variables: { projectId },
	});

	const visitorAnalytics =
		visitorsData?.visitorAnalyticsByProject as VisitorAnalytics;

	const feedbackAnalytics =
		feedbackData?.feedbackAnalyticsByProject as FeedbackAnalytics;

	const followerAnalytics =
		followerData?.followersAnalyticsByProject as FollowersAnalytics;

	let followerCount = 0;
	let groupedFollowerData: [string | Date, number][] = [];

	if (followerAnalytics?.followersOverTimePeriod.length >= 28) {
		//sort by date
		followerAnalytics?.followersOverTimePeriod.sort(function (a, b) {
			const dateA = new Date(a.createdDate);
			const dateB = new Date(b.createdDate);

			if (dateA < dateB) {
				return -1;
			}
			if (dateA > dateB) {
				return 1;
			}
			return 0;
		});
		// Group data by month
		const followerDataByMonth =
			followerAnalytics?.followersOverTimePeriod.reduce((acc, x) => {
				const date = new Date(x.createdDate);
				const month = date.toLocaleString('default', { month: 'short' });
				const year = date.getFullYear().toString();
				const monthYear = `${month} ${year}`;
				if (!acc[monthYear]) {
					acc[monthYear] = [];
				}
				acc[monthYear].push(x);
				return acc;
			}, {} as any);

		// Calculate accumulated follower count for each month
		Object.keys(followerDataByMonth).forEach((monthYear) => {
			const data = followerDataByMonth[monthYear];
			const monthFollowerCount = data.reduce((acc: number, x: any) => {
				acc += x.count;
				return acc;
			}, 0);
			followerCount += monthFollowerCount;
			groupedFollowerData.push([monthYear, followerCount]);
		});
	} else {
		// group data for each month
		groupedFollowerData = followerAnalytics?.followersOverTimePeriod.map(
			(x) => {
				followerCount += x.count;
				const date = new Date(x.createdDate);
				// Set time to 0 to match labels
				date.setHours(0, 0, 0, 0);
				return [date, followerCount];
			},
		);
	}

	let followerChartData = groupedFollowerData && [
		['Date', 'Followers'],
		...groupedFollowerData,
	];

	const minQuestionAnsweredDate = _.minBy(
		feedbackAnalytics?.questionAnswersOverTimePeriod,
		(x) => x.createdDate,
	);

	const minCommentDate = _.minBy(
		feedbackAnalytics?.commentsOverTimePeriod,
		(x) => x.createdDate,
	);

	const minAdminResponseDate = _.minBy(
		feedbackAnalytics?.adminCommentsOverTimePeriod,
		(x) => x.createdDate,
	);

	const responseStartDate = _.min([
		minAdminResponseDate,
		minCommentDate,
		minQuestionAnsweredDate,
	])?.createdDate;

	let pollResponseTotalCount = 0;
	let questionResponseTotalCount = 0;
	let adminResponseTotalCount = 0;

	let feedbackChartData: [
		string | Date,
		number | string,
		number | string,
		number | string,
	][] = [['Date', 'Poll Response', 'Question Response', 'Admin Response']];

	function compareDates(startDate: dayjs.Dayjs) {
		return (x: MetricCountOverTime) =>
			x.createdDate === startDate.format('YYYY-MM-DD');
	}

	const now = dayjs().unix();
	let startDate = dayjs(responseStartDate);
	do {
		pollResponseTotalCount +=
			feedbackAnalytics?.questionAnswersOverTimePeriod.find(
				compareDates(startDate),
			)?.count ?? 0;

		questionResponseTotalCount +=
			feedbackAnalytics?.commentsOverTimePeriod.find(compareDates(startDate))
				?.count ?? 0;

		adminResponseTotalCount +=
			feedbackAnalytics?.adminCommentsOverTimePeriod.find(
				compareDates(startDate),
			)?.count ?? 0;

		const date = startDate.toDate();
		date.setHours(0, 0, 0, 0);

		feedbackChartData.push([
			date,
			pollResponseTotalCount,
			questionResponseTotalCount,
			adminResponseTotalCount,
		]);

		startDate = startDate.add(1, 'd');
	} while (startDate.unix() < now);

	return (
		<>
			<Box px={5} py={2}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography variant="h5" sx={{ ...heading, mt: 0 }}>
								Key Statistics
							</Typography>

							<OutlinedButton
								variant="outlined"
								endIcon={<DownloadIcon />}
								onClick={() => setExportModalOpen(true)}
							>
								Download report
							</OutlinedButton>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={4}>
							<Grid item xs={4}>
								<KeyStatsCard
									title='Total feedback'
									value={isNaN(feedbackAnalytics?.commentMetrics.total + feedbackAnalytics?.pollMetrics.total) ? null : feedbackAnalytics?.commentMetrics.total + feedbackAnalytics?.pollMetrics.total}
									icon={SmsOutlinedIcon}
								/>
							</Grid>
							<Grid item xs={4}>
								<KeyStatsCard
									title='Total visitors'
									value={visitorAnalytics?.totalVisitors}
									icon={PortraitIcon}
								/>
							</Grid>
							<Grid item xs={4}>
								<KeyStatsCard
									title='Total followers'
									value={followerAnalytics?.followersMetric.total}
									icon={GroupIcon}
								/>
							</Grid>
							<Grid item xs={4}>
								<KeyStatsCard
									title='Positive or neutral sentiment'
									value={feedbackAnalytics?.sentiments.positiveAndNeutralTotal}
									icon={SentimentVerySatisfiedIcon}
								/>
							</Grid>
							<Grid item xs={4}>
								<KeyStatsCard
									title='Ideas added to plan'
									value={feedbackAnalytics?.commentsAddedToPlan}
									icon={LightbulbIcon}
								/>
							</Grid>
							<Grid item xs={4}>
								<KeyStatsCard
									title='Letters of support'
									value={feedbackAnalytics?.lettersOfSupport}
									icon={EmailOutlinedIcon}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography variant="h5" sx={heading}>
								Visitors
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<StatisticCard
									title="Total unique visitors"
									value={visitorAnalytics?.totalVisitors}
									delta={visitorAnalytics?.monthlyIncreaseInVisitors ?? 0}
									inDays={30}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography variant="h5" sx={heading}>
								Feedback
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<Item>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<StatisticCard
												title="Comments"
												value={feedbackAnalytics?.commentMetrics.total}
												delta={feedbackAnalytics?.commentMetrics.totalsForMonth}
												inDays={30}
											/>
										</Grid>
										<Grid item xs={6}>
											<Typography
												sx={{
													color: theme.customPalette.textColors.light,
													fontWeight: 500,
												}}
											>
												Ideas Added to Plan
											</Typography>
											{feedbackAnalytics?.commentsAddedToPlan || feedbackAnalytics?.commentsAddedToPlan === 0 ?
												(<Typography
													sx={{
														color: theme.customPalette.textColors.dark,
														fontWeight: 800,
														fontSize: '44px',
														lineHeight: '48px',
														my: '30px',
													}}
												>
													{feedbackAnalytics?.commentsAddedToPlan}
												</Typography>)
												:
												(<Box sx={{ minHeight: '48px', my: '24px' }}>
													<Loading />
												</Box>)
											}

										</Grid>
									</Grid>
								</Item>

							</Grid>
							<Grid item xs={3}>
								<Item>
									<StatisticCard
										title="Poll Responses"
										value={feedbackAnalytics?.pollMetrics.total}
										delta={feedbackAnalytics?.pollMetrics.totalsForMonth}
										inDays={30}
									/>
								</Item>
							</Grid>
							<Grid item xs={5}>
								<Item>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<Typography
												sx={{
													color: theme.customPalette.textColors.light,
													fontWeight: 500,
												}}
											>
												Admin Comment Replies
											</Typography>
											<Typography
												sx={{
													color: theme.customPalette.textColors.dark,
													fontWeight: 800,
													fontSize: '44px',
													lineHeight: '48px',
													my: '30px',
												}}
											>
												{feedbackAnalytics?.adminCommentReplyTotal || 0}
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Typography
												sx={{
													color: theme.customPalette.textColors.light,
													fontWeight: 500,
												}}
											>
												Admin comment reply rate
											</Typography>
											<Typography
												sx={{
													color: theme.customPalette.textColors.dark,
													fontWeight: 800,
													fontSize: '44px',
													lineHeight: '48px',
													my: '30px',
												}}
											>
												{feedbackAnalytics?.adminCommentReplyRate || 0}%
											</Typography>
										</Grid>
									</Grid>
									<LinearProgress
										variant="determinate"
										value={feedbackAnalytics?.adminCommentReplyRate}
									/>
								</Item>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} mx={1}>
						{feedbackChartData && feedbackChartData.length > 2 && (
							<Item>
								<Chart
									chartType="LineChart"
									width="100%"
									height="287px"
									data={feedbackChartData}
									options={{
										...options,
										series: {
											0: {
												color: theme.customPalette.primaryColors.grassGreen[1],
											},
											1: { color: theme.customPalette.primaryColors.orange[1] },
											2: { color: theme.customPalette.primaryColors.sky[1] },
										},
									}}
								/>
							</Item>
						)}
					</Grid>
					<Grid item xs={12}>
						{feedbackAnalytics && feedbackAnalytics.aiInsightsAvailable && (
							<Item>
								<RegularButton
									sx={{
										alignSelf: 'center',
										my: 2,
										textTransform: 'none',
										fontSize: 16,
										padding: '3px 18px',
									}}
									onClick={() => navigate(`/projects/${projectId}/insights`)}
									variant="contained"
								>
									View Feeback Insights
								</RegularButton>
							</Item>

						)}
					</Grid>
					<Grid item xs={12}>
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography variant="h5" sx={heading}>
								Followers
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={4}>
							<Grid item xs={3}>
								<StatisticCard
									title="Total followers"
									value={followerAnalytics?.followersMetric.total}
									delta={followerAnalytics?.followersMetric.totalsForMonth}
									inDays={30}
								/>
							</Grid>
							{followerChartData && followerChartData.length > 2 && (
								<Grid item xs={9}>
									<Item>
										<Chart
											chartType="LineChart"
											width="100%"
											height="250px"
											data={followerChartData}
											options={{
												...options,
												title: 'Followers over time',
												series: {
													0: { color: theme.customPalette.primaryColors.sky[1] },
												},
											}}
										/>
										<TextButton
											variant="outlined"
											endIcon={<VisibilityIcon />}
											onClick={() => setDialogOpen(true)}
											sx={{ margin: 'auto', marginRight: '-15px' }}
										>
											See All
										</TextButton>
									</Item>
								</Grid>
							)}
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h5" sx={{ ...heading, mb: 2 }}>
							Sentiment
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={8}>
							<Grid item xs={4}> 
								<SentimentCard
									sentimentLevelPercent={
										feedbackAnalytics?.sentiments.positiveSentiments
									}
									sentimentType={SentimentLevelEnum.POSITIVE}
								/>
							</Grid>
							<Grid item xs={4}> 
								<SentimentCard
									sentimentLevelPercent={
										feedbackAnalytics?.sentiments.neutralSentiments
									}
									sentimentType={SentimentLevelEnum.NEUTRAL}
								/>
							</Grid>
							<Grid item xs={4}> 
								<SentimentCard
									sentimentLevelPercent={
										feedbackAnalytics?.sentiments.negativeSentiments
									}
									sentimentType={SentimentLevelEnum.NEGATIVE}
								/>
							</Grid>
						</Grid>
					</Grid>

				</Grid>
				<AppDialog
					open={dialogOpen}
					handleRightButton={() => setDialogOpen(false)}
					handleLeftButton={() => setDialogOpen(false)}
					rightButtonLabel="Cancel"
					title={'Project Followers'}
					fullWidth={true}
					maxWidth="sm"
				>
					<FollowersList users={followerAnalytics?.followers} />
				</AppDialog>
				<ExportModal
					open={exportModalOpen}
					setOpen={setExportModalOpen}
					exportData={exportData?.analyticsExport}
					existingData={{
						totalFeedback: feedbackAnalytics?.commentMetrics.total + feedbackAnalytics?.pollMetrics.total,
						totalFollowers: followerAnalytics?.followersMetric.total,
						totalVisitors: visitorAnalytics?.totalVisitors,
						positiveNeutralFollowers: feedbackAnalytics?.sentiments.positiveAndNeutralTotal,
						ideasAddedToPlan: feedbackAnalytics?.commentsAddedToPlan,
						lettersOfSupport: feedbackAnalytics?.lettersOfSupport,
						engagementOverTime: feedbackChartData,
						positiveSentimentPercent: feedbackAnalytics?.sentiments.positiveSentiments,
						neutralSentimentPercent: feedbackAnalytics?.sentiments.neutralSentiments,
						negativeSentimentPercent: feedbackAnalytics?.sentiments.negativeSentiments
					}}
				/>
			</Box>
			<HelpScoutBeaconArticle articleId="675c55a55c65922b3a4e2d74" />
		</>
	);
};

export default Analytics;

const heading = {
	fontSize: 20,
	fontWeight: 800,
	mt: 5,
};