import { useAuth0 } from '@auth0/auth0-react';
import AppSkeleton from './components/common/AppSkeleton';
import AllRoutes from 'routes/AllRoutes';
import IntercomWidget from './components/common/IntercomWidget'; // Import IntercomWidget
import withAppProviders from 'hoc/withAppProviders';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function App() {
    const location = useLocation();
    const { isLoading } = useAuth0();

    // Example for tracking route changes
    useEffect(() => {
        if (window.heap) {
            window.heap.track('pageview', { path: location.pathname });
        }
    }, [location]);

    if (isLoading) return <AppSkeleton />;

    return (
        <>
            {/* IntercomWidget observes route changes */}
            <IntercomWidget />
            {/* Render your routes */}
            <AllRoutes />
        </>
    );
}

export default withAppProviders(App);
