import { ContentLoader } from './contentLoaderType';
import graphqlConn from '../utils/graphqlConn';
import getInfo from '../utils/getInfo';
import { InformationPlan } from '../../../../types/types';

export const infoPlansLoader: ContentLoader = async (content, project, getToken) => {
	const query = `
		query ($projectId: Int!) {
			informationPlansByProject(projectId: $projectId) {
				id
				ordinal
				file {
					id
					fileName
					url
					description
				}
				title
				url
			}
		}
	`;

	const res = await graphqlConn(getToken, query, { projectId: project?.id });
	let filteredIps = res?.data?.informationPlansByProject;
	if (content?.excludedInformationPlansIds) {
		filteredIps = filteredIps.filter(
			(obj: InformationPlan) =>
				!content?.excludedInformationPlansIds.includes(obj.id),
		);
	}

	return {
		data: filteredIps ?? ([] as InformationPlan[]),
		info: getInfo(content),
	};
};
