import { FC, useEffect, useState, ChangeEvent } from 'react';
import { Box, Typography, Checkbox, Link } from '@mui/material';
import ModuleInstanceData from '../../types/moduleInstanceData';
import { useModuleSaveData } from '../../hooks/useModuleSaveData';
import useInfoPlan from '../features/informationPlans/useInfoPlan';
import InfoPlanRow from '../features/informationPlans/InfoPlanRow';
import { InformationPlan } from '../features/informationPlans/InformationPlansForm';
import DragDropContainer from 'components/common/DragDropContainer';
import { useNavigate } from 'react-router-dom';

const InformationPlanModule: FC<ModuleInstanceData> = (props) => {
	const { content, saveDataRef, config } = props;
	const infoPlansIds = content.info?.excludedInformationPlansIds;

	const navigate = useNavigate();
	const { updateModuleSaveData } = useModuleSaveData(saveDataRef);
	const { informationPlans, handleDragEnd, handleTitleUpdate, projectId } =
		useInfoPlan();

	const [excludedInfoPlans, setExcludedInfoPlans] = useState<InformationPlan[]>(
		[],
	);

	const handleCurrentInfoPlansChange = (
		checkedInfoPlan: InformationPlan,
		checked: boolean,
	) => {
		if (!checked) {
			setExcludedInfoPlans((prevExcludedInfoPlans) => [
				...prevExcludedInfoPlans,
				checkedInfoPlan,
			]);
		} else {
			setExcludedInfoPlans((prevExcludedInfoPlans) =>
				prevExcludedInfoPlans.filter(
					(infoPlan) => infoPlan.id !== checkedInfoPlan.id,
				),
			);
		}
	};

	useEffect(() => {
		const excludedInformationPlansIds: number[] = excludedInfoPlans.map(
			(currentInfoPlan) => currentInfoPlan.id,
		);
		const newData = { content: { excludedInformationPlansIds }, config };
		updateModuleSaveData(newData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateModuleSaveData, config]);

	useEffect(() => {
		if (informationPlans && !excludedInfoPlans.length) {
			if (Array.isArray(infoPlansIds)) {
				const currentInfoPlans = informationPlans.filter((ip) =>
					infoPlansIds?.find((id: number) => ip.id === id),
				);
				if (currentInfoPlans?.length) setExcludedInfoPlans(currentInfoPlans);
			} else {
				const currentInfoPlan = informationPlans.find(
					(ip) => infoPlansIds === ip.id,
				);
				if (currentInfoPlan) setExcludedInfoPlans([currentInfoPlan]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [informationPlans, infoPlansIds]);

	return (
		<Box minWidth="500px" maxWidth="700px">
			<DragDropContainer
				droppableId={projectId}
				items={informationPlans}
				keyExtractor={(ip) => ip.id}
				handleDragEnd={handleDragEnd}
				customDataPropName="module"
				containerStyle={{ mb: 5 }}
				DraggableComponent={(ip, handle) => (
					<Box
						display="inline-flex"
						alignItems="center"
						justifyContent="center"
					>
						<Checkbox
							sx={{ marginBottom: '12px' }}
							checked={Boolean(
								!excludedInfoPlans.find(
									(currentInfoPlan) => currentInfoPlan.id === ip.id,
								),
							)}
							onChange={(e: ChangeEvent<HTMLInputElement>) =>
								handleCurrentInfoPlansChange(ip, e.target.checked)
							}
						/>
						<InfoPlanRow
							informationPlan={ip}
							handle={handle}
							onTitleUpdate={handleTitleUpdate}
						/>
					</Box>
				)}
			/>
			{informationPlans?.length <= 0 && (
				<Typography fontSize={16}>
					No documents are available. Please add one in the{' '}
					<Link
						onClick={() => {
							navigate(`/projects/${projectId}/information-plans`);
						}}
					>
						Information & Plans
					</Link>{' '}
					page
				</Typography>
			)}
		</Box>
	);
};

export default InformationPlanModule;
