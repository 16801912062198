import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { useUserContext } from '../../providers/UserProvider';
import { useEnv } from '../../providers/EnvProvider';

// Helper functions
const bootIntercom = (appId: string, user?: { firstName: string }) => {
    if (typeof window.Intercom === 'function') {
        window.Intercom('boot', user ? { app_id: appId, name: user.firstName } : { app_id: appId });
    }
};

const shutdownIntercom = () => {
    if (typeof window.Intercom === 'function') {
        window.Intercom('shutdown');
    }
};

const IntercomWidget = () => {
    const { isAuthenticated, isLoading } = useAuth0();
    const { user } = useUserContext();
    const { intercomWidgetId } = useEnv();
    const location = useLocation();

    // Define the routes where Intercom should be disabled
    const routesToDisableIntercom = [
        /^\/projects\/[^/]+\/analytics$/, // "/projects/:projectId/analytics"
        /^\/projects\/[^/]+\/comments$/,  // "/projects/:projectId/comments"
    ];

    // Check if the current route matches the disabled routes
    const shouldDisableIntercom = routesToDisableIntercom.some((regex) =>
        regex.test(location.pathname)
    );

    useEffect(() => {
        // Shutdown Intercom widget before initializing
        shutdownIntercom();

        if (!shouldDisableIntercom && !isLoading) {
            // Boot Intercom with user details or anonymously
            if (isAuthenticated && user) {
                bootIntercom(intercomWidgetId, user);
            } else {
                bootIntercom(intercomWidgetId);
            }
        }

        return () => {
            // Cleanup when the component unmounts
            shutdownIntercom();
        };
    }, [isAuthenticated, isLoading, user, intercomWidgetId, location.pathname, shouldDisableIntercom]);

    return null; // No UI needed
};

export default IntercomWidget;
