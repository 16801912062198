import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import TextButton from './styled/TextButton';
import { useEnv } from '../../providers/EnvProvider';

interface HelpScoutLinkProps {
  articleId: string;
  label: string;
  sx?: any;
  endIcon?: React.ReactNode;
}

const HelpScoutLink: React.FC<HelpScoutLinkProps> = ({ articleId, label, sx, endIcon }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const hoverTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const beaconContainerRef = useRef<HTMLDivElement>(null);
  const { helpScoutBeaconId } = useEnv();
  useEffect(() => {
    if (!window.Beacon) {
      const script = document.createElement('script');
      script.src = 'https://beacon-v2.helpscout.net';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        window.Beacon('init', helpScoutBeaconId);
      };
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        beaconContainerRef.current &&
        !beaconContainerRef.current.contains(event.target as Node)
      ) {
        if (window.Beacon) {
          window.Beacon('close');
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [helpScoutBeaconId]);


  const handleMouseEnter = () => {
    hoverTimeout.current = setTimeout(() => {
      if (buttonRef.current) {
        const clickEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        buttonRef.current.dispatchEvent(clickEvent);
      }
    }, 300);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
      hoverTimeout.current = null;
    }
  };

  return (
    <Box my={2}>
      <div ref={beaconContainerRef}>
        <a
          href="#"
          role="button"
          aria-label={label}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <TextButton
            ref={buttonRef}
            data-beacon-article-inline={articleId}
            sx={{
              ...sx,
              fontWeight: 800,
              textTransform: 'capitalize',
              fontSize: 16,
              marginLeft: '-12px',
              px: 2,
            }}
            endIcon={endIcon ? endIcon : <VideoLibraryIcon />}
          >
            {label}
          </TextButton>
        </a>
      </div>
    </Box>
  );
};

export default HelpScoutLink;
