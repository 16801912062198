import { ComponentType } from 'react';

import AuthorizedUrqlProvider from '../providers/AuthorizedUrqlProvider';
import UserProvider from '../providers/UserProvider';
import { MapScriptLoaderProvider } from '../components/modules/mapModule/MapScriptLoaderProvider';
import SnackbarProvider from '../providers/SnackBarProvider';
import AbilityProvider from '../providers/CaslAbilityProvider';
import ActionConfirmationContextProvider from '../providers/ActionConfirmationProvider';
import AppTheme from 'components/themes/AppTheme';

const withAppProviders = (App: ComponentType) => {
	return (
		<MapScriptLoaderProvider>
			<AppTheme>
				<SnackbarProvider>
					<AuthorizedUrqlProvider>
						<UserProvider>
							<AbilityProvider>
								<ActionConfirmationContextProvider>
									<App />
								</ActionConfirmationContextProvider>
							</AbilityProvider>
						</UserProvider>
					</AuthorizedUrqlProvider>
				</SnackbarProvider>
			</AppTheme>
		</MapScriptLoaderProvider>
	);
};

export default withAppProviders;
