import { FC } from 'react';
import { Box, IconButton, InputLabel, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { ImageInfo } from 'types/imageInfo';
import RegularButton from 'components/common/styled/RegularButton';
import OutlinedButton from 'components/common/styled/OutlinedButton';
import RegularTextInput from 'components/common/styled/RegularTextInput';
import { TextEditor } from '../../common/TextEditor';
import { RichTextContent } from 'types/richTextContent';

interface UpdateEditorProps {
	editorState: RichTextContent;
	setEditorState: (editorState: RichTextContent) => void;
	onClose: () => void;
	title: string | undefined;
	handleTitleUpdate: (title: string) => void;
	errors: Record<string, string>;
	onSave: (published?: Date) => void;
	published?: Date;
	isNewUpdate: boolean;
	addImage: (imageInfo: ImageInfo) => void;
}

const UpdateEditor: FC<UpdateEditorProps> = (props) => {
	const {
		editorState,
		setEditorState,
		onClose,
		title,
		handleTitleUpdate,
		onSave,
		published,
		isNewUpdate,
		addImage,
	} = props;

	const editorAction = (actionType: string, payload: object) => {
		if (actionType === 'image_add') {
			addImage(payload as ImageInfo);
		}
	};

	const today = new Date();

	return (
		<Box maxWidth={728} p={3}>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				mb={2}
			>
				<Typography fontWeight="800" fontSize={20}>
					{isNewUpdate ? 'Create' : 'Edit'} Update
				</Typography>
				<IconButton onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</Box>

			<Box display="flex" gap={2}>
				<Box
					display="flex"
					flexDirection="column"
					sx={{
						width: '95%',
					}}
				>
					<InputLabel id="updateTitle">Update Title</InputLabel>
					<RegularTextInput
						value={title}
						fullWidth
						size="small"
						onBlur={(e) => handleTitleUpdate(e.target.value)}
						onChange={(e) => handleTitleUpdate(e.target.value)}
						sx={{ fontSize: 24 }}
					/>
				</Box>
			</Box>

			<Typography
				variant="body2"
				mb={2}
				sx={{ color: title?.length! > 80 ? 'red' : 'black' }}
			>
				{title?.length || 0}/80
			</Typography>

			<TextEditor
				editorState={editorState}
				setEditorState={setEditorState}
				action={editorAction}
				withImage={true}
			/>

			<Box
				display="flex"
				alignItems="center"
				gap={2}
				justifyContent="end"
				mt={2}
			>
				<OutlinedButton
					variant="outlined"
					onClick={onClose}
					color="secondary"
					style={btnStyle as any}
				>
					Cancel
				</OutlinedButton>
				{!published && (
					<RegularButton
						variant="contained"
						onClick={() => onSave()}
						autoFocus
						type="submit"
						style={btnStyle as any}
					>
						Save Draft
					</RegularButton>
				)}

				<RegularButton
					variant="contained"
					onClick={() => onSave(today)}
					autoFocus
					type="submit"
					style={btnStyle as any}
				>
					{published ? 'Save & Publish' : 'Publish'}
				</RegularButton>
			</Box>
		</Box>
	);
};

export default UpdateEditor;

const btnStyle = {
	textTransform: 'none',
	fontSize: 16,
	width: 144,
};
