import { useEnv } from '../../providers/EnvProvider';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

interface HelpScoutBeaconArticleProps {
  articleId: string;
}

const HelpScoutBeaconArticle: React.FC<HelpScoutBeaconArticleProps> = ({ articleId }) => {
  const location = useLocation();
  const { helpScoutBeaconId } = useEnv();
  const articleOpened = useRef(false); // Prevent double-opening the article

  useEffect(() => {
    if (!window.Beacon) {
      const script = document.createElement('script');
      script.src = 'https://beacon-v2.helpscout.net';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        // Initialize the Beacon and show the floating button
        window.Beacon('init', helpScoutBeaconId);
        openArticleOnce();
      };
    } else {
      // If Beacon is already loaded, show the button and open the article once
      openArticleOnce();
    }

    function openArticleOnce() {
      window.Beacon('open'); // Ensure the button appears
      if (!articleOpened.current && window.Beacon && articleId) {
        window.Beacon('article', articleId);
        articleOpened.current = true; // Prevent re-opening
      }
    }

    // Cleanup: Close the Beacon when the component unmounts
    return () => {
      if (window.Beacon) {
        window.Beacon('close');
      }
    };
  }, [articleId, helpScoutBeaconId, location]);

  return null; // This component does not render anything
};

export default HelpScoutBeaconArticle;
