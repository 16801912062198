import {
	Note as NoteIcon,
	Flag as FlagIcon,
	CalendarMonth as CalendarMonthIcon,
	EventAvailable as EventAvailableIcon,
	Edit as EditIcon,
	ChatBubble as ChatBubbleIcon,
	PhoneIphone as PhoneIphoneIcon,
	Lightbulb as LightbulbIcon,
	QuestionAnswer as QuestionAnswerIcon,
	Done as DoneIcon,
} from '@mui/icons-material';
import { Comment, Image } from 'types/types';
import { RichTextContent } from 'types/richTextContent';

export interface UpdateTemplate {
	id: number;
	name?: string;
	title: string;
	richText: RichTextContent;
	published?: Date;
	imageScale?: string;
	boundingBoxPosition?: string;
	image?: Image | null;
	images?: Image[] | null;
}

const templates = (projectName: string) => [
	{
		name: 'Blank',
		title: 'New Blank Update',
		Icon: NoteIcon,
		richText: [
			{
				text: '',
			},
		],
	},
	{
		name: 'Introduction',
		title: `Introducing ${projectName}`,
		Icon: FlagIcon,
		richText: [
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'Welcome to our new website! This space is designed to keep you informed and engaged about '
				},
				{
				  'bold': true,
				  'text': '[PROJECT NAME]'
				},
				{
				  'text': ' in '
				},
				{
				  'bold': true,
				  'text': '[LOCATION]'
				},
				{
				  'text': '. We’d love to hear from you and to gather your valuable ideas and feedback.'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'Feel free to explore and see what your neighbors are talking about in the '
				},
				{
					'url': '/feedback',
					'type': 'link',
					'children': [
					  {
						'text': 'feedback section',
						'underline': true
					  }
					]
				},
				{
				  'text': '. We encourage you to share your thoughts and join in on the conversation too! Every comment and suggestion you make here goes directly to us at '
				},
				{
				  'bold': true,
				  'text': '[PROJECT TEAM]'
				},
				{
				  'text': ', we’ll respond as soon as we can. We\'re excited you’re here and hope to hear from you so we can work together to shape this community!'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'Don’t forget to click the '
				},
				{
				  'bold': true,
				  'text': 'Subscribe',
				  'underline': true
				},
				{
				  'text': ' button so you can receive updates directly via email and stay informed on all project news, events and developments!'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'bold': true,
				  'text': '<Insert project rendering, photo of meeting or the existing site or neighborhood>'
				}
			  ]
			}
		],
	},
	{
		name: 'Upcoming Meeting',
		title: 'Join us at our upcoming meeting!',
		Icon: CalendarMonthIcon,
		richText: [
			{
			  'type': 'paragraph',
			  'style': 'margin-left:0px;',
			  'children': [
				{
				  'text': ''
				},
				{
				  'text': 'We’ll be hosting a meeting on '
				},
				{
				  'bold': true,
				  'text': '[ADD DATE, TIME & LOCATION]'
				},
				{
				  'text': ' to discuss the '
				},
				{
				  'bold': true,
				  'text': '[PROJECT NAME]'
				},
				{
				  'text': '. We\'re excited to be sharing updates and ideas on topics like:'
				}
			  ]
			},
			{
			  'type': 'bulleted-list',
			  'children': [
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'bold': true,
						  'text': 'Example',
						  'italic': true
						},
						{
						  'text': ': New project renderings & community or resident benefits',
						  'italic': true
						}
					  ]
					}
				  ]
				},
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'bold': true,
						  'text': 'Example',
						  'italic': true
						},
						{
						  'text': ': Collaborating with local artists for a mural',
						  'italic': true
						}
					  ]
					}
				  ]
				},
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'bold': true,
						  'text': 'Example',
						  'italic': true
						},
						{
						  'text': ': Adding a new transit stop to enhance the area',
						  'italic': true
						}
					  ]
					}
				  ]
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'style': 'margin-left:0px;',
			  'children': [
				{
				  'text': 'If you can’t make it, the meeting recording will be posted '
				},
				{
				  'bold': true,
				  'text': '[HERE]'
				},
				{
				  'text': '. We will also share an update summarizing the meeting on this website—so be sure to subscribe and stay connected.'
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'style': 'margin-left:0px;',
			  'children': [
				{
				  'text': 'Can’t attend in person or virtually? You can still share your ideas and feedback with us anytime! Just visit the '
				},
				{
					'url': '/feedback',
					'type': 'link',
					'children': [
					  {
						'text': 'feedback section',
						'underline': true
					  }
					]
				},
				{
				  'text': '.'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'style': 'margin-left:0px;',
			  'children': [
				{
				  'text': 'Thank you!'
				}
			  ]
			}
		],
	},
	{
		name: 'Meeting Recap',
		title: 'Thanks for meeting with us! What\'s next?',
		Icon: EventAvailableIcon,
		richText: [
			{
			  'type': 'paragraph',
			  'style': 'text-align:left;',
			  'children': [
				{
				  'text': 'Thank you to everyone who joined us at '
				},
				{
				  'bold': true,
				  'text': '[ADD MEETING DETAILS]'
				},
				{
				  'text': '! If you couldn’t make it, don’t worry—you can view the recording '
				},
				{
				  'bold': true,
				  'text': '[HERE]'
				},
				{
				  'text': '.'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'style': 'text-align:left;',
			  'children': [
				{
				  'text': 'To recap, during the meeting we covered:'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'bulleted-list',
			  'children': [
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'bold': true,
						  'text': 'Example',
						  'italic': true
						},
						{
						  'text': ': New project renderings & community or resident benefits',
						  'italic': true
						}
					  ]
					}
				  ]
				},
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'bold': true,
						  'text': 'Example',
						  'italic': true
						},
						{
						  'text': ': Collaborating with local artists for a mural',
						  'italic': true
						}
					  ]
					}
				  ]
				},
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'bold': true,
						  'text': 'Example',
						  'italic': true
						},
						{
						  'text': ': Adding a new transit stop to enhance the area',
						  'italic': true
						}
					  ]
					}
				  ]
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'style': 'text-align:left;',
			  'children': [
				{
				  'text': 'It was wonderful to see so many community members share ideas on the project, and we really appreciated everyone’s feedback around '
				},
				{
				  'bold': true,
				  'text': '[ADD KEY FEEDBACK DISCUSSED]'
				},
				{
				  'text': '.'
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'style': 'text-align:left;',
			  'children': [
				{
				  'text': 'Please keep sharing your thoughts and questions in the '
				},
				{
					'url': '/feedback',
					'type': 'link',
					'children': [
					  {
						'text': 'feedback section',
						'underline': true
					  }
					]
				},
				{
				  'text': ' —we look forward to hearing more! '
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'style': 'text-align:left;',
			  'children': [
				{
				  'bold': true,
				  'text': '<INSERT PHOTO FROM MEETING - CROWD, AGENDA, OR TEAM PRESENTING>'
				}
			  ]
			}
		],
	},
	{
		name: 'What We\'ve Heard',
		title: `What neighbors are saying about ${projectName}`,
		Icon: QuestionAnswerIcon,
		richText: [
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'Hello Everyone!'
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'It’s been great to see all of your ideas and thoughtful feedback on the website. So far we are hearing '
				},
				{
				  'bold': true,
				  'text': '[2-3]'
				},
				{
				  'text': ' main themes: '
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'bold': true,
				  'text': '[Highlight 2-3 main themes from the feedback data - examples include:'
				},
				{
				  'bold': true,
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'bulleted-list',
			  'children': [
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'bold': true,
						  'text': 'Traffic & Parking'
						}
					  ]
					}
				  ]
				},
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'bold': true,
						  'text': 'Desire for Locally-Owned Businesses and Unique Retail'
						}
					  ]
					}
				  ]
				},
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'bold': true,
						  'text': 'Family-Friendly Amenities and Dining Options'
						}
					  ]
					}
				  ]
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'bold': true,
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'bold': true,
				  'text': 'Explain each theme and pull out some great quotes from people]'
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'It’s exciting to see the buzz around these topics and we can’t wait to dive in more. '
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'Thank you for helping shape our community.'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'We will continue to gather your feedback and monitor the results closely. Stay tuned for more updates as we head into the '
				},
				{
				  'bold': true,
				  'text': '[XZY SEASON/PHASE]'
				},
				{
				  'text': ', and if you have any additional feedback or questions, please share it in the '
				},
				{
					'url': '/feedback',
					'type': 'link',
					'children': [
					  {
						'text': 'feedback section',
						'underline': true
					  }
					]
				},
				{
				  'text': '.  '
				}
			  ]
			}
		],
	},
	{
		name: 'Incorporated Feedback',
		title: 'How we\'re using your feedback',
		Icon: LightbulbIcon,
		richText: [
			{
			  'type': 'paragraph',
			  'style': 'text-align:left;',
			  'children': [
				{
				  'text': 'Thank you for the insightful comments, questions, and ideas you’ve shared with us so far. It’s been great to see you participating and taking an active role in shaping your community! We are noticing a theme and wanted to let you know that we hear you on '
				},
				{
				  'bold': true,
				  'text': '[ADD TOPIC(S)]'
				},
				{
				  'text': '. '
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'style': 'text-align:left;',
			  'children': [
				{
				  'bold': true,
				  'text': '<INSERT SCREENSHOT OF COMMENTS>'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'style': 'text-align:left;',
			  'children': [
				{
				  'bold': true,
				  'text': '[ADD DETAILS, e.g. what has or has not been done to address the concern. Why or why not are you directly addressing it and how.]'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'style': 'text-align:left;',
			  'children': [
				{
				  'text': 'We’re excited to announce that we have now '
				},
				{
				  'bold': true,
				  'text': '[ADD DETAILS, e.g., moved into a new phase, completed a draft plan, received approval]'
				},
				{
				  'text': '. Based on your input, we’ve made updates to include '
				},
				{
				  'bold': true,
				  'text': '[INSERT UPDATE HERE]'
				},
				{
				  'text': '.'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'style': 'text-align:left;',
			  'children': [
				{
				  'text': 'Your participation in this process has been invaluable, and we encourage you to continue sharing your feedback and questions in the '
				},
				{
					'url': '/feedback',
					'type': 'link',
					'children': [
						{
						'text': 'feedback section',
						'underline': true
						}
					]
				},
				{
				  'text': '. Thank you for following along on this journey and your continued involvement will only make a stronger '
				},
				{
				  'bold': true,
				  'text': '[NEIGHBORHOOD/COMMUNITY]'
				},
				{
				  'text': '. Thank you!'
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'style': 'text-align:left;',
			  'children': [
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'style': 'text-align:left;',
			  'children': [
				{
				  'bold': true,
				  'text': '<Insert image of benefit: outdoor space, retail rendering, new crosswalk, etc.>'
				}
			  ]
			}
		],
	},
	{
		name: 'New Comment Topics',
		title: 'New opportunity to share feedback',
		Icon: ChatBubbleIcon,
		richText: [
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'Thank you to everyone who has shared their feedback so far! Your insights have given us a clearer picture of the community’s priorities.  So far, we’ve incorporated:'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'bulleted-list',
			  'children': [
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'bold': true,
						  'text': 'UPDATES TO PLANS, ex.'
						},
						{
						  'text': ' Open space programming'
						}
					  ]
					}
				  ]
				},
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'bold': true,
						  'text': 'UPDATES TO PLANS, ex.'
						},
						{
						  'text': ' Bike connectivity'
						}
					  ]
					}
				  ]
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'To keep the conversation going, we’ve added new questions about:'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'bulleted-list',
			  'children': [
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'bold': true,
						  'text': 'ADD KEY TOPICS, Ex.'
						},
						{
						  'text': ' Retail'
						}
					  ]
					}
				  ]
				},
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'bold': true,
						  'text': 'ADD KEY TOPICS, Ex.'
						},
						{
						  'text': ' Public art'
						}
					  ]
					}
				  ]
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'We’d love to hear your thoughts on these new topics! Head over to our '
				},
				{
					'url': '/feedback',
					'type': 'link',
					'children': [
					  {
						'text': 'feedback section',
						'underline': true
					  }
					]
				},
				{
				  'text': ' and share your ideas to help us continue improving our project plans. We look forward to hearing from you!'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'bold': true,
				  'text': '<INSERT SCREENSHOT OF NEW COMMENT TOPIC>'
				}
			  ]
			}
		],
	},
	{
		name: 'Signage Announcement',
		title:
			'Introducing a new way to engage with the project: Text us your ideas!!',
		Icon: PhoneIphoneIcon,
		richText: [
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'Make your voice heard from wherever, whenever, by texting us your feedback and ideas! We want to thank you for the feedback we\'ve received thus far and we\'re eager to continue to hear from people who are in and around the '
				},
				{
				  'bold': true,
				  'text': '[LOCAL NEIGHBORHOOD/COMMUNITY]'
				},
				{
				  'text': '. Go ahead and respond to the prompt on the signage and see it appear online in the project '
				},
				{
					'url': '/feedback',
					'type': 'link',
					'children': [
					  {
						'text': 'feedback section',
						'underline': true
					  }
					]
				},
				{
				  'text': '.  '
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'Be on the lookout for these new signs that will be posted around '
				},
				{
				  'bold': true,
				  'text': 'ADD SIGN LOCATION(S)'
				},
				{
				  'text': ':'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'bold': true,
				  'text': '<IMAGE OF SIGNAGE>'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'We\'re starting to receive insightful feedback on '
				},
				{
				  'bold': true,
				  'text': '[XYZ TOPIC]'
				},
				{
				  'text': ' like this comment below from community members who '
				},
				{
				  'bold': true,
				  'text': '[live/work in area]'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'bold': true,
				  'text': '<SCREENSHOT OF COMMENTS> '
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'If you have any additional feedback or questions, please share it in the project '
				},
				{
					'url': '/feedback',
					'type': 'link',
					'children': [
					  {
						'text': 'feedback section',
						'underline': true
					  }
					]
				},
				{
				  'text': '.'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'Thank you to everyone who is commenting and sharing their feedback to improve the neighborhood, we can’t wait to hear more!'
				}
			  ]
			}
		],
	},
	{
		name: 'Addressing Concerns',
		title: 'Addressing Concerns Around [Project Element] — We Hear You!',
		Icon: EditIcon,
		richText: [
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'Thank you all for the insightful comments, questions, and ideas you’ve shared with us so far. '
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'We\'ve received many questions regarding '
				},
				{
				  'bold': true,
				  'text': '[INSERT PROJECT ELEMENT]'
				},
				{
				  'text': ' and wanted to address these questions in more detail here. '
				},
				{
				  'bold': true,
				  'text': '[PROVIDE BACKGROUND OR CORRECT MISINFORMATION]'
				},
				{
				  'text': '. Based on the feedback we’ve heard so far, we’ve updated plans to include '
				},
				{
				  'bold': true,
				  'text': '[INSERT UPDATE HERE]'
				},
				{
				  'text': '.'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'Your participation has been invaluable, and we encourage you to continue sharing your feedback and questions here '
				},
				{
				  'bold': true,
				  'text': '[LINK]'
				},
				{
				  'text': '. Thank you for being an essential part of this process!'
				}
			  ]
			}
		],
	},
	{
		name: 'Final Update',
		title: 'Thank you for adding your feedback to [PROJECT TITLE]. Comments are now closed.',
		Icon: DoneIcon,
		richText: [
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'After '
				},
				{
				  'bold': true,
				  'text': 'X'
				},
				{
				  'text': ' months of invaluable feedback and an ongoing engagement dialogue we\'re wrapping up online outreach for this project. We’ve appreciated hearing your input that has improved plans through:'
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'bold': true,
				  'text': '[Insert feedback themes that were incorporated] Examples: '
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'numbered-list',
			  'children': [
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'text': 'Public art'
						}
					  ]
					}
				  ]
				},
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'text': 'Open space programming'
						}
					  ]
					}
				  ]
				},
				{
				  'type': 'list-item',
				  'children': [
					{
					  'type': 'paragraph',
					  'children': [
						{
						  'text': 'Transportation/connectivity'
						}
					  ]
					}
				  ]
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'text': 'Now that the project/plan has received approval, we’ll begin the construction/implementation phase in '
				},
				{
				  'bold': true,
				  'text': '<Timeframe>'
				},
				{
				  'text': '. The '
				},
				{
				  'bold': true,
				  'text': '[PROJECT NAME]'
				},
				{
				  'text': ' website will close on '
				},
				{
				  'bold': true,
				  'text': '[X date]'
				},
				{
				  'text': ' '
				},
				{
				  'bold': true,
				  'text': 'or shortly'
				},
				{
				  'text': '. If you have any further questions, you can reach out to our team at: '
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'bold': true,
				  'text': 'INFO@PROJECTTEAM.COM',
				  'underline': true
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'bold': true,
				  'text': 'Thank you for improving your neighborhood by participating in this online engagement process. '
				},
				{
				  'text': ''
				}
			  ]
			},
			{
			  'type': 'paragraph',
			  'children': [
				{
				  'bold': true,
				  'text': '<Insert final rendering or public hearing image>'
				}
			  ]
			}
		],
	},
];

export const incorporatedCommentTemplate = (comment: Comment) => { 
	return {
		id: 0,
		name: 'Using Your Feedback',
		title: 'How we\'ve used your feedback',
		richText: [
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Thank you all for the insightful comments, questions, and ideas you’ve shared with us so far. We’re proud of how we’re incorporating this feedback into project plans.',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'margin-left:10%',
				children: [
					{
						text: `${comment.createdByUser.fullName}`,
						bold: true,
					},
					{
						text: `\n"${comment.commentText}"`,
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
			{
				type: 'paragraph',
				style: 'text-align:left;',
				children: [
					{
						text: 'Your participation during this process has been very helpful. Please continue to share your feedback and questions here ',
					},
					{
						text: 'LINK',
						bold: true,
					},
					{
						text: '. Thank you!',
					},
				],
			},
			{
				type: 'paragraph',
				children: [
					{
						text: '',
					},
				],
			},
		],
	};
};

export default templates;
